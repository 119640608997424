import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

/**
 * More info here ->
 *   https://pierrehedkvist.com/posts/react-state-url
 */
export const useStateParam = (
    paramName: string,
    initialValue?: string
): [string | null, (state: string|null, push?: boolean) => void] => {
    const history = useHistory();
    const search = new URLSearchParams(history.location.search);

    const existingValue = search.get(paramName);
    const [state, setState] = useState<string|null>(
        existingValue ? existingValue : initialValue ? initialValue : null
    );

    useEffect(() => {
        // Updates state when user navigates backwards or forwards in browser history
        if (existingValue && existingValue !== state) {
            setState(existingValue);
        }
    }, [existingValue, state]);

    const onChange = (s: string|null, push: boolean = true) => {
        setState(s);
        const searchParams = new URLSearchParams(history.location.search);
        if (s) {
            searchParams.set(paramName, s);
        } else {
            searchParams.delete(paramName);
        }
        const pathname = history.location.pathname;
        if (push) {
            history.push({pathname, search: searchParams.toString()});
        } else {
            history.replace({pathname, search: searchParams.toString()});
        }
    };

    return [state, onChange];
}