import { FC } from "react";
import { useGoHomeOrBack } from "../common/GoHomeOrBack";
import { HeaderNavigationBar } from "../common/HeaderNavigationBar";
import { Logo } from "../common/Logo";
import {PageInfo, Pages, ProductWizardSequence} from "./NewProductWizard";

export interface NewProductWizardNavigationBarProps {
    pages: Pages;
    productWizardSequence: ProductWizardSequence;
    onClose?: () => void;
}

export const NewProductWizardNavigationBar: FC<NewProductWizardNavigationBarProps> = ({
    pages,
    productWizardSequence,
    onClose
}) => {
    const goHomeOrBack = useGoHomeOrBack();

    const renderLogo = () => {
        return (
            <Logo
                className="jc-nav-bar-logo"
                width={30}
                height={30}
                onClick={goHomeOrBack}
            />
        );
    };

    const getContext = () => {
        if (productWizardSequence === ProductWizardSequence.SIGNUP_PRODUCT
            || productWizardSequence === ProductWizardSequence.NEW_PRODUCT) {
            return "New Product";
        } else {
            return "Edit Product";
        }
    };

    const breadcrumbs = Object.values(pages).map(({getBreadcrumbComponent}: PageInfo) => getBreadcrumbComponent());
    let currentStepNumber: number = 1;
    const currentPageInfo: PageInfo | undefined = Object.values(pages).find((pageInfo: PageInfo, index: number) => {
        if (pageInfo.isCurrentPage) {
            currentStepNumber = index + 1;
            return pageInfo;
        }
    });

    return (
        <HeaderNavigationBar
            onClose={onClose}
            breadcrumbs={breadcrumbs}
            logo={renderLogo()}
            contextTitle={getContext()}
            showStepCounter={true}
            currentStepNumber={currentStepNumber}
            pageTitle={currentPageInfo?.pageTitle} />
    )
}
