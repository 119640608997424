import { gql } from "@apollo/client";
import { apolloClient, BaseQueryResult, jssaQuery, useBaseQuery } from "../datastore";
import { StripeCustomer, StripePaymentMethod } from "../../../common/models";

export { BillingForm } from "./BillingForm";

const createStripeSetupIntentMutation = gql`
    mutation createStripeSetupIntent {
        createStripeSetupIntent
    }
`;

export const createStripeSetupIntent = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        apolloClient.mutate({
            mutation: createStripeSetupIntentMutation
        }).then(({data: {createStripeSetupIntent}}) => {
            resolve(createStripeSetupIntent);
        }).catch((error) => {
            reject(error);
        });
    });
};

const stripePublishableKeyQuery = gql`
    query stripePublishableKey {
        stripePublishableKey
    }
`;

export const fetchStripePublishableKey = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
        apolloClient.query({
            query: stripePublishableKeyQuery
        }).then(({data: {stripePublishableKey}}) => {
            resolve(stripePublishableKey);
        }).catch((error) => {
            reject(error);
        });
    });
};

const stripePortalSessionQuery = gql`
    {
        stripeCustomer {
            portalSessionUrl
        }
    }
`;

type StripeCustomerResponseType = { stripeCustomer: StripeCustomer };
export const fetchPortalSessionUrl = async (): Promise<string | null> => {
    return new Promise((resolve, reject) => {
        jssaQuery<StripeCustomerResponseType>({
            query: stripePortalSessionQuery
        }).then(({data: {stripeCustomer}}) => {
            resolve(stripeCustomer.portalSessionUrl || null);
        }).catch((error) => {
            reject(error);
        });
    });
};

const stripePaymentMethodsQuery = gql`
    {
        stripeCustomer {
            id
            paymentMethods {
                brand
                expiry
                last4
            }
        }
    }
`;

type StripeResponseType = { stripeCustomer: { paymentMethods: StripePaymentMethod[] } };
export const useStripePaymentMethodsQuery = (): BaseQueryResult<StripePaymentMethod[]> => {
    return useBaseQuery<StripePaymentMethod[], StripeResponseType>(
        stripePaymentMethodsQuery,
        {},
        ({data}) => data.stripeCustomer.paymentMethods
    );
};
