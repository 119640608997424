import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as H from "history";
import { analytics } from "./index";

export const PageViewListener = () => {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState<H.Location | undefined>();

    useEffect(() => {
        const hasChanged = !prevLocation
            || (prevLocation.pathname !== location.pathname)
            || (prevLocation.search !== location.search)
            || (prevLocation.hash !== location.hash);
    
        if (hasChanged) {
            setPrevLocation(location);
            analytics.sendPageView();
        }
    }, [location, prevLocation]);

    return null;
};
