import { InfoSignIcon } from "evergreen-ui";
import { NotificationCategory } from "../../../common/enums";
import { ColorPalette } from "../context/Theme";

export type MessageViewStyle = {
    backgroundColor: string;
    icon: JSX.Element;
};

export type MessageViewStyles = Partial<Record<NotificationCategory, MessageViewStyle>>;

export const MESSAGE_VIEW_STYLES: MessageViewStyles = {
    [NotificationCategory.ProductDevUpdate]: {
        backgroundColor: ColorPalette.greyBackground,
        icon: <InfoSignIcon color={ColorPalette.neutralColor}/>
    },
    [NotificationCategory.ProductDevConfirmation]: {
        backgroundColor: ColorPalette.greyBackground,
        icon: <InfoSignIcon color={ColorPalette.neutralColor}/>
    }, 
};