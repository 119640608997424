import {
    Heading,
    Pane,
    Paragraph,
    Table,
    TableBody,
    TableRow,
    Text,
    TickIcon,
} from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { LaunchType, LaunchTypeDisplayValues } from "../../../../common/enums";
import { SectionStatus } from "../../../../common/enums/SectionStatus";
import { TabStatus } from "../../../../common/enums/TabStatus";
import { ProductListing } from "../../../../common/models";
import { TimelineStatus } from "../../../../common/models/TimelineStatus";
import { ColorPalette } from "../../context/Theme";
import { IconPair, TimelineBadges, TimelineIcons } from "./TimelineIcons";

interface SectionTitleProps {
    title: string;
    subtitle?: string;
    state: keyof typeof SectionStatus;
}

const SectionTitle = (props: SectionTitleProps) => {
    const { title, subtitle, state } = props;
    return(
        <Pane
            width={"100%"}
            display={"flex"}
            flex={"100%"}
            alignContent={"flex-start"}
            textAlign={"left"}
        >
            <Heading paddingBottom={"16px"} width={"100%"} fontWeight={"unset"} size={600}>
                {title} {subtitle && <Text size={600} color={ColorPalette.lightGreyText}> - {subtitle}</Text>}
            </Heading>
            { TimelineBadges[state] }
        </Pane>
    );
}

interface SectionTabProps {
    state: keyof typeof TabStatus;
    icon?: IconPair
    title: string;
    optional?: boolean;
    actionIcon?: IconPair;
    durationText?: string;
    description?: string;
    onClick?: () => void;
}

const SectionTab = (props: SectionTabProps) => {

    const { state, icon, title, optional, actionIcon, durationText, description, onClick } = props;

    const resolveTabBackgroundColor = (state: keyof typeof TabStatus) => {
        switch (state) {
            case TabStatus.locked:
                return ColorPalette.greyBackground;
            case TabStatus.done:
            case TabStatus.doneEditable:
                return ColorPalette.statusGoodBackground;
            case TabStatus.normal:
            default:
                return ColorPalette.white;
        }
    }

    return(
        <TableRow
            isSelectable={durationText === undefined && (state === TabStatus.normal || state === TabStatus.doneEditable)}
            height={"auto"}
            padding={"16px"}
            backgroundColor={resolveTabBackgroundColor(state)}
            display={"flex"}
            flexWrap={"wrap"}
            width={"100%"}
            onSelect={onClick}
        >
            <Pane
                width={"100%"}
                display={"flex"}
                flex={"100%"}
                alignContent={"flex-start"}
                alignItems={"center"}
                textAlign={"left"}
            >
                {state === TabStatus.done || state === TabStatus.doneEditable ? icon?.done : icon?.normal}
                <Heading
                    whiteSpace={"normal"}
                    color={state === TabStatus.done || state === TabStatus.doneEditable ? ColorPalette.primaryAccent : "black"}
                    paddingLeft={"8px"}
                    width={"100%"}
                    textAlign={"left"}
                    fontWeight={"unset"}
                    size={400}>
                    {title} {optional && <Text size={400} color={ColorPalette.lightGreyText}> - Optional</Text>}
                </Heading>
                {durationText && (state !== TabStatus.done && state !== TabStatus.doneEditable) &&
                    <Paragraph whiteSpace={"nowrap"} size={300} color={ColorPalette.lightGreyText}>{durationText}</Paragraph>}
                {!durationText && (state === TabStatus.normal) && actionIcon?.normal}
                {(state === TabStatus.done || state === TabStatus.doneEditable) && <TickIcon color={ColorPalette.primaryAccent} />}
            </Pane>
            <Paragraph whiteSpace={"normal"} textAlign={"left"} marginLeft={"24px"} width={"100%"} color={ColorPalette.lightGreyText}>
                {description || ""}
            </Paragraph>
        </TableRow>
    );
}

export interface TimelineTabProps {
    initialProductListing: ProductListing;
    timelineStatus: TimelineStatus;
    storeId?: string;
    twilioConversationId?: string;
    shopDomain?: string;
    goToMenuTab(index: number, showSubmit?: boolean): void;
    refreshTimeline(): void;
}

export const TimelineTab = (props: TimelineTabProps) => {

    const {
        initialProductListing,
        timelineStatus,
        storeId,
        twilioConversationId,
        shopDomain,
        goToMenuTab,
    } = props
    const history = useHistory();

    const goToMessages = (draftMessage?: string) => {
        if (twilioConversationId) {
            history.push(`/messages/${twilioConversationId || ""}`, {
                draftMessage: draftMessage
            });
        }
    }

    const isJulyHibernationExcluded = (): boolean => {
        return initialProductListing?.store.julySleepExcluded ?? false;
    };

    const getSubmitState = (): keyof typeof TabStatus => {
        if (isJulyHibernationExcluded()) {
            return timelineStatus.getStarted.tabs.submitProductDetails;
        } else {
            return TabStatus.locked;
        }
    };

    return(
        <>
            { timelineStatus && <Pane padding={"24px"} maxWidth={"768px"}>
                <Pane marginBottom={"24px"}>
                    <SectionTitle state={timelineStatus.getStarted.status} title={"Get Started"}/>
                    <Table borderRadius={"8px"} overflow={"clip"}>
                        <TableBody>
                            <SectionTab
                                state={timelineStatus.getStarted.tabs.creditCard}
                                title={"Add a credit card to this store."}
                                icon={TimelineIcons["creditcard"]}
                                onClick={() => history.push("/settings/billing")}
                                actionIcon={TimelineIcons["arrowright"]} />
                            <SectionTab
                                state={timelineStatus.getStarted.tabs.contactInfo}
                                title={"Add mobile phone number"}
                                description={"Provide your number to receive notifications about messages and updates."}
                                icon={TimelineIcons["phone"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() => history.push("/settings")} />
                            <SectionTab
                                state={timelineStatus.getStarted.tabs.storeDetails}
                                title={"Add store details"}
                                icon={TimelineIcons["shop"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                optional={true}
                                onClick={() => history.push(`/stores/${storeId|| ""}`)}
                                description={"Add details + images that will apply to every product in this store."} />
                            <SectionTab
                                state={timelineStatus.getStarted.tabs.addProductDetails}
                                title={"Add my product details."}
                                icon={TimelineIcons["manuallyentereddata"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() => goToMenuTab(1)} />
                            <SectionTab
                                state={getSubmitState()}
                                title={isJulyHibernationExcluded() ? "Click here to submit this product!" : "We are currently at capacity and are not accepting new products."}
                                icon={TimelineIcons["sendmessage"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() => goToMenuTab(1, true)} />
                        </TableBody>
                    </Table>
                </Pane>

                <Pane marginBottom={"24px"}>
                    <SectionTitle state={timelineStatus.design.status} title={"Design"}/>
                    <Table borderRadius={"8px"} overflow={"clip"}>
                        <TableBody>
                            <SectionTab
                                state={timelineStatus.design.tabs.designer}
                                title={"Get connected to my designer."}
                                icon={TimelineIcons["refresh"]}
                                durationText={"Less than a day"} />
                            <SectionTab
                                state={timelineStatus.design.tabs.designConcept}
                                title={"Approve design concept once complete."}
                                icon={TimelineIcons["lightbulb"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I approve of the design concept. Let's move on to the manufacturing drawing based on this concept!")}
                                description={"Let us know the design concept is approved."} />
                            <SectionTab
                                state={timelineStatus.design.tabs.manufacturingDrawing}
                                title={"Approve manufacturing drawing once complete."}
                                icon={TimelineIcons["edit"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I approve of the manufacturing drawing. Let's submit this for sampling!")}
                                description={"Let us know the manufacturing drawing is approved."} />
                            <SectionTab
                                state={timelineStatus.design.tabs.packaging}
                                title={"Packaging"}
                                optional={true}
                                icon={TimelineIcons["tag"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I'd like to work on some custom packaging for this product. Here's what I'm thinking: ")}
                                description={"Let your designer know if you are interested in custom packaging."} />
                            <SectionTab
                                state={timelineStatus.design.tabs.bannerArtwork}
                                title={"Banner artwork"}
                                optional={true}
                                icon={TimelineIcons["application"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I'd like to work on some new banner artwork for my storefront. Here's what I'm thinking: ")}
                                description={"Let us know the manufacturing drawing is approved."} />
                        </TableBody>
                    </Table>
                </Pane>

                <Pane marginBottom={"24px"}>
                    <SectionTitle state={timelineStatus.sample.status} title={"Sample"}/>
                    <Table borderRadius={"8px"} overflow={"clip"}>
                        <TableBody>
                            <SectionTab
                                state={timelineStatus.sample.tabs.photosReceived}
                                title={"Receive sample photos."}
                                icon={TimelineIcons["camera"]}
                                durationText={"About 2 weeks"} />
                            <SectionTab
                                state={timelineStatus.sample.tabs.photosApproved}
                                title={"Approve sample photos."}
                                icon={TimelineIcons["thumbsup"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("the sample looks good based on the photos. Please ship the sample to me!")}
                                description={"Let us know if we can send the physical sample to you or if you have feedback."} />
                            <SectionTab
                                state={timelineStatus.sample.tabs.sampleReceived}
                                title={"Receive physical sample."}
                                icon={TimelineIcons["box"]}
                                durationText={"About 2 weeks"} />
                            <SectionTab
                                state={timelineStatus.sample.tabs.sampleApproved}
                                title={"Approve physical sample."}
                                icon={TimelineIcons["thumbsup"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I received the physical sample, and I approve!")}
                                description={"Let us know in the chat if the physical sample is approved!"} />
                        </TableBody>
                    </Table>
                </Pane>

                { timelineStatus.preOrderLaunch && <Pane>
                    <SectionTitle state={timelineStatus.preOrderLaunch.status} title={"Launch"} subtitle={LaunchTypeDisplayValues[LaunchType.preOrder]}/>
                    <Table borderRadius={"8px"} overflow={"clip"}>
                        <TableBody>
                            <SectionTab
                                state={timelineStatus.preOrderLaunch.tabs.paid}
                                title={"Pay for design + sampling."}
                                icon={TimelineIcons["dollar"]}
                                description={"We'll send the invoice through the chat to confirm."}
                                durationText={""}/>
                            <SectionTab
                                state={timelineStatus.preOrderLaunch.tabs.retailPricing}
                                title={"Confirm retail pricing."}
                                icon={TimelineIcons["tag"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I would like to list this product at the following retail price: ")}
                                description={"Upload photos, confirm description + pricing, and start selling!"} />
                            <SectionTab
                                state={timelineStatus.preOrderLaunch.tabs.storeDomain}
                                title={"Confirm store domain."}
                                icon={TimelineIcons["globe"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("Please set up my store with the following domain: ")}
                                description={"Set up a new website domain, or switch an existing one."} />
                            <SectionTab
                                state={timelineStatus.preOrderLaunch.tabs.productPhotos}
                                title={"Your product photos"}
                                optional={true}
                                icon={TimelineIcons["cloudupload"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("Sending over some photos that I took with the products! Please include these in the product listing.")}
                                description={"Send us any photos of your own that you want to include on the website!"} />
                            <SectionTab
                                state={timelineStatus.preOrderLaunch.tabs.startSelling}
                                title={"Start selling!"}
                                icon={TimelineIcons["shop"]}
                                onClick={() => {
                                    if (shopDomain) {
                                        window.open(`https://${shopDomain}`, '_blank');
                                    }
                                }}
                                actionIcon={TimelineIcons["share"]} />
                            <SectionTab
                                state={timelineStatus.preOrderLaunch.tabs.manufacture}
                                title={"Manufacture products."}
                                icon={TimelineIcons["build"]}
                                durationText={"About 6 weeks"} />
                        </TableBody>
                    </Table>
                </Pane> }

                { timelineStatus.inStockLaunch && <Pane>
                    <SectionTitle state={timelineStatus.inStockLaunch.status} title={"Launch"} subtitle={LaunchTypeDisplayValues[LaunchType.inStock]}/>
                    <Table border={`1px solid ${ColorPalette.borderColor}`} borderRadius={"8px"} overflow={"clip"}>
                        <TableBody>
                            <SectionTab
                                state={timelineStatus.inStockLaunch.tabs.purchaseInventory}
                                title={"Purchase inventory."}
                                icon={TimelineIcons["shoppingcart"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I'd like to place a production order for [Number] units.")}
                                description={"Let us know the quantity and size breakdown of your inventory"} />
                            <SectionTab
                                state={timelineStatus.inStockLaunch.tabs.manufacture}
                                title={"Manufacture products."}
                                icon={TimelineIcons["build"]}
                                durationText={"About 6 weeks"} />
                            <SectionTab
                                state={timelineStatus.inStockLaunch.tabs.storeDomain}
                                title={"Confirm store domain."}
                                icon={TimelineIcons["globe"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("Please set up my store with the following domain: ")}
                                description={"Set up a new website domain, or switch an existing one."} />
                            <SectionTab
                                state={timelineStatus.inStockLaunch.tabs.retailPricing}
                                title={"Confirm retail pricing."}
                                icon={TimelineIcons["tag"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("I would like to list this product at the following retail price: ")}
                                description={"Upload photos, confirm description + pricing, and start selling!"} />
                            <SectionTab
                                state={timelineStatus.inStockLaunch.tabs.productPhotos}
                                title={"Your product photos"}
                                optional={true}
                                icon={TimelineIcons["cloudupload"]}
                                actionIcon={TimelineIcons["arrowright"]}
                                onClick={() =>
                                    goToMessages("Sending over some photos that I took with the products! Please include these in the product listing.")}
                                description={"Send us any photos of your own that you want to include on the website!"} />
                            <SectionTab
                                state={timelineStatus.inStockLaunch.tabs.startSelling}
                                title={"Start selling!"}
                                icon={TimelineIcons["shop"]}
                                onClick={() => {
                                    if (shopDomain) {
                                        window.open(`https://${shopDomain}`, '_blank');
                                    }
                                }}
                                actionIcon={TimelineIcons["share"]} />
                        </TableBody>
                    </Table>
                </Pane> }
            </Pane> }
        </>
    );
}
