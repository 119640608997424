import { useEffect, useRef, useState } from "react";
import { Pane, PaneProps } from "evergreen-ui";

/**
 * Position is omitted from the PaneProps inheritance
 * because it is overridden internally and set to "relative".
 */
interface Props extends Omit<PaneProps, "position"> {
    /** Prevents resizing of the component. */
    resizeDisabled?: boolean;

    /** Overrides the PaneProps width by limiting it to a string (px or %) or number. */
    width: string | number;
}

export const ResizablePane = ({children, width, resizeDisabled = false, ...paneProps}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const draggableRef = useRef<HTMLDivElement>(null);
    const x = useRef<number>(0);

    const [resizedWidth, setResizedWidth] = useState<number | string>(width);

    // Initialize the document-wide gesture handling when `mousedown` event is fired.
    const onMouseDown = (e: MouseEvent) => {
        x.current = e.clientX;
        addListeners();
    };

    // Actually resize the div here. This is called when the `mousemove` event is fired.
    const onResize = (e: MouseEvent) => {
        if (resizeDisabled) {
            // `onResize` shouldn't be called if `disabled` is `true` since we
            // would've already unsubscribed from the event before the props changed, but in
            // case this event was already in-flight, we return here.
            return;
        }

        if (containerRef.current && x.current) {
            const dx = e.clientX - x.current;
            x.current = e.clientX;
            setResizedWidth(containerRef.current.clientWidth + dx);

            // Prevent the mouse event from bubbling up to other elements. This mitigates
            // accidental highlighting of text elements outside the draggable div.
            e.preventDefault();
        }
    };

    const removeListeners = () => {
        document.removeEventListener("mousemove", onResize);
        document.removeEventListener("keydown", onEscapeKey);

        // Unsubscribe this callback from the `mouseup` event.
        document.removeEventListener("mouseup", removeListeners);
    };

    const addListeners = () => {
        document.addEventListener("mousemove", onResize);
        document.addEventListener("keydown", onEscapeKey);

        // When the mouse is no longer dragging, unsubscribe all the listeners.
        document.addEventListener("mouseup", removeListeners);
    };

    const onEscapeKey = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            removeListeners();
        }
    };

    useEffect(() => {
        // Props just changed, so we have to reset the width.
        setResizedWidth(width);

        if (resizeDisabled) {
            return;  // Quick return, since listeners aren't needed.
        }

        const draggableDiv = draggableRef.current;
        if (draggableDiv) {
            // Subscribe to `mousedown` events on the draggable div when the component mounts.
            draggableDiv.addEventListener("mousedown", onMouseDown);
        }

        return () => {
            // Props are about to change (or the component is unmounting) so clean up the listeners.
            if (draggableDiv) {
                // Unsubscribe from `mousedown` events when the component unmounts.
                draggableDiv.removeEventListener("mousedown", onMouseDown);
            }

            // Clean up any other active listeners.
            removeListeners();
        }
    }, [width, resizeDisabled]);

    return (
        <Pane
            {...paneProps}
            ref={containerRef}
            position={"relative"}
            width={resizedWidth}
        >
            <Pane width={"100%"}>
                {children}
            </Pane>
            <Pane
                ref={draggableRef}
                style={{
                    cursor: resizeDisabled ? "auto" : "col-resize",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    // This centers the draggable div along the right border
                    // of the container with equal buffer on either side.
                    right: -8,
                    width: 16,
                }}
            />
        </Pane>
    );
}
